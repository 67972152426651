import { Inject, InjectionToken } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { AUTH_SERVICE, IAuthService } from '../services/auth.service';

export const ROLE_GUARD = new InjectionToken<CanActivate>('ROLE_GUARD');

export class RoleGuard implements CanActivate {
	constructor(
		@Inject(AUTH_SERVICE) private authService: IAuthService,
		private router: Router,
		private readonly jwtHelperService: JwtHelperService
	) {}

	private currentRole = '';
	private expectedRoles = [];

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.expectedRoles = next.data.expectedRoles;
		const accessToken = this.jwtHelperService.tokenGetter();
		if (accessToken) {
			this.currentRole = this.jwtHelperService.decodeToken().role.definition;
		}

		if (this.expectedRoles.find((expectedRole) => expectedRole === this.currentRole)) {
			return true;
		} else {
			return this.router.parseUrl('/auth/unauthorized');
		}
	}
}
