import { Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthRedirectionService } from '../services/auth-redirection.service';
import { AUTH_SERVICE, IAuthService } from '../services/auth.service';

export class AuthenticationGuard implements CanActivate, CanActivateChild {
	constructor(
		@Inject(AUTH_SERVICE) private readonly auth: IAuthService,
		private readonly router: Router,
		private readonly authRedirectionService: AuthRedirectionService
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.auth.isAuthenticated()) {
			return this.auth.refreshToken().pipe(
				map(() => {
					return true;
				}),
				catchError(() => {
					this.authRedirectionService.saveRedirectionUrl(state?.url);
					this.router.navigate(['auth/login']);
					return of(false);
				})
			);
		}
		return true;
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(childRoute, state);
	}
}
