import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import {
	API_SERVICE,
	AUTH_SERVICE,
	IsAuthenticatedGuard,
	KcAuthGuard,
	SsoRoleGuard,
	ROLE_GUARD,
	UnsavedDataService,
} from '@oper-client/shared/data-access';
import { ApiV2Module, KcAuthService } from '@oper-client/shared/api-v2.0';
import { KcSsoAppRoutingModule } from './kc-sso-app-routing.module';
import { Router } from '@angular/router';
import { KcSsoAppComponent } from './kc-sso-app.component';
import { CommonBrokerageAppModule } from '../common-brokerage-app/common-brokerage-app.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { OverlayService } from '@oper-client/shared/overlay';

@NgModule({
	declarations: [KcSsoAppComponent],
	imports: [
		CommonModule,
		CommonBrokerageAppModule,
		KcSsoAppRoutingModule,
		KeycloakAngularModule,
		/* Initialize generated client */
		ApiV2Module.forRoot({
			authService: KcAuthService,
			deps: [KeycloakService, API_SERVICE, CUSTOMER_INSIGHTS_CONFIG, Router, OverlayService, UnsavedDataService],
		}),
	],
	providers: [
		[
			/* AUTH providers */
			{
				provide: KcAuthGuard,
				deps: [AUTH_SERVICE, Router],
			},
			{
				provide: ROLE_GUARD,
				deps: [AUTH_SERVICE, Router],
				useClass: SsoRoleGuard,
			},
			{
				provide: IsAuthenticatedGuard,
				deps: [AUTH_SERVICE, Router],
			},
		],
	],
	bootstrap: [KcSsoAppComponent],
})
export class KcSsoAppModule {}
