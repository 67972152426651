import { Resource } from './resource.model';
import { Address } from './address.model';
import { LoanRequest } from './loan-request.model';

export interface MortgageRank {
	creditProvider: Resource;
	id: number;
	amount: number;
	rank: number;
}

// interface Ownership {
// 	client: Client;
// 	id: number;
// 	ownershipType: Resource;
// 	percentage: number;
// }

export interface Renovation {
	renovationType: Resource;
	readonly id?: number;
	amountContractor: number;
	amountDiy?: number;
}

export enum RealtyPurpose {
	buyOut = 'buyOut',
	refinance = 'refinance',
	renovation = 'renovation',
	purchase = 'purchase',
	newBuild = 'newBuild',
}

export interface Realty {
	readonly id: number;
	readonly loanRequest: LoanRequest;

	// Characteristics
	address?: Address;
	estimationExpirationDate?: Date;
	newRealty: boolean;
	realtyType?: Resource;
	rooms: number;
	surface: number;
	purposes: Array<Resource>;
	renovations: Array<Renovation>;
	usageTypes?: Array<Resource>;
	mortgageRanks?: Array<MortgageRank>;
	livingPercentage?: number;
	coLivingPurpose?: Resource;
	coLivingGroupType?: Resource;
	homeType?: Resource;
	// Values
	price: string;
	readonly renovationPrice: number;
	priceLand: string;
	priceBuilding: string | number;
	venalValueBefore?: string;
	venalValueAfter?: string;
	forcedValueAfter?: string;

	// Austrian specific stuff
	districtCourt: string;
	landRegister: string;
	pictureUrl: string;
	plotNumber: string;
	propertyNumber: string;

	// Belgian specific stuff
	cadastralLocation: string;

	// Swiss specific stuff
	dateOfPurchase?: string;
	isLuxuryProperty?: boolean;
	isVacationHome?: boolean;
	numberOfApartments?: number;
	numberOfBathrooms?: number;
	numberOfSeparateGarages?: number;
	numberOfUndergroundParkingSpaces?: number;
	terraceBalconySurface?: number;
	yearOfBuilt?: number;

	// EPC
	epcBeforeRenovations?: number;
	epcAfterRenovations?: number;
	epcDate?: string;
	epcContractNumber: string;
	mainFirstResidence?: boolean;
	energeticRenovations?: boolean;
	kleinBeschrijf?: boolean;

	eLevel: number;

	hasApplicableLandCharges?: boolean;
	isExistingObject?: boolean;

	comfortType?: Resource;
	constructionType?: Resource;
	conditionOfProperty?: Resource;
	constructionLogbookNumber?: string;
	hasLift?: boolean;
	housingType?: boolean;
	loanPurpose?: Resource;
	features?: number[];
}
