import { deepClone } from '@oper-client/shared/util-object';

export enum SortDirection {
	ASC = 'asc',
	DESC = 'desc',
	NONE = 'none',
}

export interface SortOrder<T> {
	sortBy: T;
	sortDirection: SortDirection;
}

export function validateArray(a: any[] | undefined | null): void {
	if (typeof a === 'undefined') {
		throw new Error('Passed argument is undefined.');
	} else if (!Array.isArray(a)) {
		throw new Error('Passed argument is not an array.');
	}
}

function allPropsMatching(item: Record<string, any>, predicate: Record<string, any>): boolean {
	return !Object.keys(predicate)
		.reduce((acc, key) => [...(item[key] === predicate[key] ? [true] : [false]), ...acc], [])
		.includes(false);
}

export function compare(a: number | string, b: number | string, isAsc = true): number {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function orderBy(collection: any[], prop: string, order: SortDirection = SortDirection.ASC, arrayDeepClone = false): any[] {
	validateArray(collection);
	const arrayToSort: any[] = arrayDeepClone ? deepClone(collection) : [...collection];

	return arrayToSort.sort((a, b) => compare(a[prop], b[prop], order === SortDirection.ASC));
}

export function orderByWithDeepClone(collection: any[], prop: string, order: SortDirection = SortDirection.ASC): any[] {
	return orderBy(collection, prop, order, true);
}

export function find(collection: any[], predicate: Record<string, any>, fromIndex = 0): any | undefined {
	validateArray(collection);

	if (typeof predicate === 'undefined') {
		throw new Error('Predicate is undefined.');
	} else if (Object.keys(predicate).length === 0) {
		throw new Error(`Predicate doesn't have a key.`);
	}

	return collection.find((item, index) => {
		return index >= fromIndex ? allPropsMatching(item, predicate) : undefined;
	});
}

export function min(array: number[] | string[]): number | string {
	validateArray(array);
	return array.slice().sort(compare)[0];
}

export function max(array: number[] | string[]): number | string {
	validateArray(array);
	return array.slice().sort(compare)[array.length - 1];
}

export function subtract(minuend: number, subtrahend: number): number {
	if (typeof minuend === 'undefined') {
		throw new Error('Minuend is undefined.');
	} else if (typeof subtrahend === 'undefined') {
		throw new Error('Subtrahend is undefined.');
	} else if (isNaN(minuend)) {
		throw new Error('Minuend is not a number.');
	} else if (isNaN(subtrahend)) {
		throw new Error('Subtrahend is not a number.');
	}

	return minuend - subtrahend;
}

export function stringSearch(collection: any[], searchString: string): any[] {
	if (typeof collection === 'undefined') {
		throw new Error('Collection is undefined.');
	} else if (typeof searchString === 'undefined') {
		return collection;
	}

	return collection.filter((item: any) =>
		Object.keys(item)
			.reduce((acc: string, key: string) => acc + ` ${item[key]}`, ``)
			.toLowerCase()
			.includes(searchString.toLowerCase())
	);
}

export function distinct(collection: any[]): any[] {
	return collection.filter(onlyUnique);
}

export function onlyUnique(value: any, index: number, self): boolean {
	return self.indexOf(value) === index;
}

export function getNextSortOrderState<T>(sortBy: T, currentSortOrder: SortOrder<T>, defaultSortOrder?: SortOrder<T>): SortOrder<T> {
	let nextSortDirection: SortDirection = SortDirection.ASC;

	if (currentSortOrder?.sortBy === sortBy) {
		switch (currentSortOrder.sortDirection) {
			case SortDirection.ASC:
				nextSortDirection = SortDirection.DESC;
				break;
			case SortDirection.DESC:
				nextSortDirection = SortDirection.NONE;
				if (defaultSortOrder) {
					return defaultSortOrder;
				}
				break;
			case SortDirection.NONE:
				nextSortDirection = SortDirection.ASC;
				break;
			default:
				break;
		}
	}

	return { sortBy, sortDirection: nextSortDirection };
}

export function getSortedList<T>(list: any[], sortOrder: SortOrder<T>, defaultSortOrder?: SortOrder<T>): any[] {
	if (!sortOrder) {
		return defaultSortOrder ? getSortedList(list, defaultSortOrder) : list;
	}

	switch (sortOrder.sortDirection) {
		case SortDirection.ASC:
			return orderBy(list, <string>sortOrder.sortBy, SortDirection.ASC);

		case SortDirection.DESC:
			return orderBy(list, <string>sortOrder.sortBy, SortDirection.DESC);

		default:
			return defaultSortOrder ? getSortedList(list, defaultSortOrder) : list;
	}
}

export function updateListItem<T>(list: T[], index: number, updatedItem: T): T[] {
	if (typeof list[index] === 'undefined') {
		return list;
	}

	const listClone = deepClone(list);

	listClone[index] = updatedItem;

	return listClone;
}
