import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UnsavedDataService {
	readonly dataUnsaved$ = new BehaviorSubject<boolean>(false);
	readonly dataValid$ = new BehaviorSubject<boolean>(true);
	readonly navigationConfirmed$: Subject<boolean> = new Subject();
	readonly saveAndProceed$ = new Subject<void>();

	protected trackedForms: Map<string, { form: FormGroup; initialValue: any; dirty: boolean; subscription?: Subscription }> = new Map();
	protected trackedData: Map<string, { initialValue: any; valid: boolean; value?: any }> = new Map();

	protected _trackingEnabled = false;

	get trackingEnabled(): boolean {
		return this._trackingEnabled;
	}

	set trackingEnabled(value: boolean) {
		this._trackingEnabled = value;
	}
	constructor() {}

	trackForm(id: string, form: FormGroup) {
		if (!this.trackingEnabled) {
			return;
		}
		let trackData = this.trackedForms.get(id);
		if (trackData?.subscription) {
			trackData.subscription.unsubscribe();
		}
		trackData = {
			form: form,
			initialValue: JSON.stringify(form.value),
			dirty: false,
		};
		trackData.subscription = form.valueChanges
			.pipe(takeWhile(() => trackData.dirty == false))
			.subscribe(() => (trackData.dirty = true));
		this.trackedForms.set(id, trackData);
	}

	untrackForm(id: string) {
		const trackData = this.trackedForms.get(id);
		if (trackData?.subscription) {
			trackData.subscription.unsubscribe();
		}
		this.trackedForms.delete(id);
	}
	trackData(id: string, value: any) {
		if (!this.trackingEnabled) {
			return;
		}
		const trackData = {
			valid: true,
			initialValue: JSON.stringify(value),
			value: value,
		};
		this.trackedData.set(id, trackData);
	}

	submitDataChanges(id: string, value: any, valid = true) {
		if (!this.trackingEnabled) {
			return;
		}
		const trackData = this.trackedData.get(id);
		if (trackData) {
			trackData.value = value;
			trackData.valid = valid;
		}
	}

	untrackData(id: string) {
		this.trackedData.delete(id);
	}

	untrackAll() {
		for (const trackData of this.trackedForms.entries()) {
			trackData[1].subscription.unsubscribe();
		}
		this.trackedForms.clear();
		this.trackedData.clear();
	}
	get navigationAllowed(): Observable<boolean> {
		setTimeout(() => {
			const formsStatus = this.status;
			if (formsStatus.changed) {
				this.dataUnsaved$.next(true);
			} else {
				this.navigationConfirmed$.next(true);
			}
			this.dataValid$.next(formsStatus.valid);
		});
		return this.navigationConfirmed$;
	}

	get status(): { changed: boolean; valid: boolean } {
		const status = {
			changed: false,
			valid: true,
		};
		for (const trackData of this.trackedForms.entries()) {
			const form = trackData[1].form;
			if (trackData[1].initialValue !== JSON.stringify(form.value)) {
				status.changed = true;
			}
			if (!form.pristine && !form.valid) {
				status.valid = false;
			}
		}
		for (const trackData of this.trackedData.entries()) {
			const data = trackData[1];
			if (data.initialValue !== JSON.stringify(data.value)) {
				status.changed = true;
			}
			if (!data.valid) {
				status.valid = false;
			}
		}
		return status;
	}
}
