import { Injectable } from '@angular/core';
import { UtilService } from './util.service';

const keysToIgnore = ['pricingConfiguration'];

@Injectable({
	providedIn: 'root',
})
export class CaseStyleService {
	constructor(private readonly utilService: UtilService) {}

	public toSnakeCase = function (camelCaseString) {
		const capitalLetters = camelCaseString.match(/([A-Z])/g);
		if (!capitalLetters) {
			return camelCaseString;
		}
		let snakeCaseString = camelCaseString.toString();
		for (let i = 0, n = capitalLetters.length; i < n; i++) {
			snakeCaseString = snakeCaseString.replace(new RegExp(capitalLetters[i]), '_' + capitalLetters[i].toLowerCase());
		}
		if (snakeCaseString.slice(0, 1) === '_') {
			snakeCaseString = snakeCaseString.slice(1);
		}
		return snakeCaseString;
	};

	public convertKeysToSnakeCase = function (camelCase) {
		if (this.utilService.isObject(camelCase)) {
			const snakeCase = {};

			Object.keys(camelCase).forEach((key) => {
				if (key.startsWith('_json_data_')) {
					// Data and key is passed as it is. The case: we need to generate JSON data and passed to third-party service
					snakeCase[this.toSnakeCase(key.replace('_json_data_', ''))] = camelCase[key];
					return;
				} else if (keysToIgnore.includes(key)) {
					snakeCase[this.toSnakeCase(key)] = camelCase[key];
					return;
				}

				snakeCase[this.toSnakeCase(key)] = this.convertKeysToSnakeCase(camelCase[key]);
			});

			return snakeCase;
		} else if (this.utilService.isArray(camelCase)) {
			return camelCase.map((i) => {
				return this.convertKeysToSnakeCase(i);
			});
		}
		return camelCase;
	};

	public toCamelCase(snakeCaseString: string): string {
		return snakeCaseString.replace(/(_\w)/g, function (m) {
			return m[1].toUpperCase();
		});
	}

	public convertKeysToCamelCase(snakeCase) {
		if (this.utilService.isObject(snakeCase)) {
			const camelCase = {};

			Object.keys(snakeCase).forEach((key) => {
				const camelCaseKey = this.toCamelCase(key);
				if (keysToIgnore.includes(camelCaseKey)) {
					camelCase[camelCaseKey] = snakeCase[key];
					return;
				}
				camelCase[camelCaseKey] = this.convertKeysToCamelCase(snakeCase[key]);
			});

			return camelCase;
		} else if (Array.isArray(snakeCase)) {
			return snakeCase.map((i) => {
				return this.convertKeysToCamelCase(i);
			});
		}
		return snakeCase;
	}
}
