import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, LoanRequestEntityService } from '@oper-client/shared/data-access';
import { Realty } from '@oper-client/shared/data-model';

@Injectable()
export class RealtyService implements LoanRequestEntityService<Realty> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	getAll(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<Realty[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/realties/`, params);
	}

	get(loanRequestId: number, propertyId: number, params: HttpParams = new HttpParams()): Observable<Realty> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/realties/${propertyId}/`, params);
	}

	create(loanRequestId: number, property: Realty, params: HttpParams = new HttpParams()): Observable<Realty> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/realties/`, property, params);
	}

	update(
		loanRequestId: number,
		propertyId: number,
		property: Partial<Realty>,
		params: HttpParams = new HttpParams()
	): Observable<Realty> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/realties/${propertyId}/`, property, params);
	}

	delete(loanRequestId: number, propertyId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/realties/${propertyId}/`);
	}
}
