export enum FeatureFlag {
	CORE_ADVISOR_OFFERS_TOTAL = 'coreAdvisorOffersTotal',
	CORE_ADVISOR_SIMULATOR_PRODUCTS = 'coreAdvisorSimulatorProducts',
	CORE_ADVISOR_SIMULATOR_OFFERS = 'coreAdvisorSimulatorOffers',
	CORE_USE_FINANCIAL_ASSETS = 'coreUseFinancialAssets',
	CORE_TERMS_AND_CONDITIONS = 'coreTermsAndConditions',
	CORE_SIGN_IN_PHONE_VERIFICATION = 'coreSignInPhoneVerification',
	COMMON_DISABLE_POWERED_BY_OPER = 'commonDisablePoweredByOper',
	COMMON_LOAN_REQUEST_DOCUMENT_MANAGEMENT_ONLY = 'commonLoanRequestDocumentManagementOnly',
	BROKERAGE_DISABLE_ACCEPTANCE_CRITERIA_MODAL = 'brokerageDisableAcceptanceCriteriaModal',
	BROKERAGE_ADDITIONAL_FINANCING_NEED = 'brokerageAdditionalFinancingNeed',
	BROKERAGE_COMMISSIONS = 'brokerageCommissions',
	BROKERAGE_DATA_CONNECTION = 'brokerageDataConnection',
	BROKERAGE_SEND_TO_LENDER_NAVIGATION_BUTTON_HIDDEN = 'brokerageSendToLenderNavigationButtonHidden',
	BROKERAGE_DEED = 'brokerageDeed',
	BROKERAGE_DOCUMENT_LIBRARY = 'brokerageDocumentLibrary',
	BROKERAGE_FIRE_INSURANCE = 'brokerageFireInsurance',
	BROKERAGE_GENERATE_GDPR_AND_BROKER_PRESENTATION = 'brokerageGenerateGdprAndBrokerPresentation',
	BROKERAGE_GENERATE_LOAN_REQUEST_FORM = 'brokerageGenerateLoanRequestForm',
	BROKERAGE_GENERATE_LOAN_REQUEST_FORM_FOR_BORROWER = 'brokerageGenerateLoanRequestFormForBorrower',
	BROKERAGE_GENERATE_LOAN_REQUEST_FORM_DEPENDENT_ON_SIMULATION = 'brokerageGenerateLoanRequestFormDependentOnSimulation',
	BROKERAGE_GENERATE_SIMULATOR_DOCUMENT = 'brokerageGenerateSimulationDocument',
	BROKERAGE_GENERATE_HANDOVER_DOCUMENT = 'brokerageGenerateHandoverDocument',
	BROKERAGE_GENERATE_CLIENT_FRIENDLY_DOCUMENT = 'brokerageGenerateClientFriendlyDocument',
	BROKERAGE_IDENTITY_BE_ID = 'brokerageIdentityBeid',
	BROKERAGE_IDENTITY_ITS_ME = 'brokerageIdentityItsme',
	BROKERAGE_LIABILITY_INSURANCE = 'brokerageLiabilityInsurance',
	BROKERAGE_MORTGAGE_SIMULATOR = 'brokerageMortgageSimulator',
	BROKERAGE_SHOW_OFFER = 'brokerageShowOffer',
	BROKERAGE_DISABLE_CONSENT_DISCLAIMER = 'brokerageDisableConsentDisclaimer',
	BROKERAGE_HIDE_COLLATERAL = 'brokerageHideCollateral',
	BROKERAGE_PRICING = 'brokeragePricing',
	BROKERAGE_PRE_APPROVAL_STATUS_SUMMARY = 'brokeragePreApprovalStatusSummary',
	BROKERAGE_CLIENTS_RELATIONSHIPS = 'brokerageClientRelationships',
	BROKERAGE_MULTIPLE_ADDRESSES = 'brokerageMultipleAddresses',
	BROKERAGE_OFFERS = 'brokerageOffers',
	BROKERAGE_ALLOW_PICK_OFFER_ON_FAILING_ACC_RULES = 'brokerageAllowPickOfferOnFailingAccRules',
	BROKERAGE_OFFER_LIST_MULTI_SELECT = 'brokerageOfferListMultiSelect',
	BROKERAGE_ENABLE_INFORMATIVE_DOCUMENT = 'brokerageEnableInformativeDocument',
	BROKERAGE_PRODUCT_EXPLORER_APRC_CALCULATION = 'brokerageProductExplorerAprcCalculation',
	BROKERAGE_ZENDESK = 'brokerageZendesk',
	BROKERAGE_ENABLE_DOUBLE_DOCUMENT_VERIFICATION = 'brokerageEnableDoubleDocumentVerification',
	BROKERAGE_DISABLE_TIMELINE_EVENTS = 'brokerageDisableTimelineEvents',
	BROKERAGE_PRODUCT_EXPLORER_SHOW_PESSIMISTIC_SCENARIO = 'brokerageProductExplorerShowPessimisticScenario',
	BROKERAGE_SIMULATION_INVITE_BORROWER = 'brokerageSimulationInviteBorrower',
	BROKERAGE_ENABLE_AI_DOCUMENT_VERIFICATION = 'brokerageEnableAiDocumentVerification',
	SELF_SERVICE_APPLICATION_FLOW = 'selfServiceApplicationFlow',
	SELF_SERVICE_APPOINTMENT_BOOKING = 'selfServiceAppointmentBooking',
	SELF_SERVICE_APPOINTMENT_BOOKING_NAVIGATION = 'selfServiceAppointmentBookingNavigation',
	SELF_SERVICE_CONTACT_OPENING_HOURS = 'selfServiceContactOpeningHours',
	SELF_SERVICE_CONTACT_PHONE = 'selfServiceContactPhone',
	SELF_SERVICE_CONTACT_FORM = 'selfServiceContactForm',
	SELF_SERVICE_CONTACT_SCREEN = 'selfServiceContactScreen',
	SELF_SERVICE_CLIENT_ADDRESS = 'selfServiceClientAddress',
	SELF_SERVICE_DOCUMENTS = 'selfServiceDocuments',
	SELF_SERVICE_MANDATORY_REAL_ESTATE_DATA = 'selfServiceMandatoryRealEstateData',
	SELF_SERVICE_MORTGAGE_SIMULATOR = 'selfServiceMortgageSimulator',
	SELF_SERVICE_MORTGAGE_SIMULATOR_DURATION_INPUT = 'selfServiceMortgageSimulatorDurationInput',
	SELF_SERVICE_MORTGAGE_SIMULATOR_INTEREST_INPUT = 'selfServiceMortgageSimulatorInterestInput',
	SELF_SERVICE_MORTGAGE_SIMULATOR_SELECTED_REGION = 'selfServiceMortgageSimulatorSelectedRegion',
	SELF_SERVICE_OFFICE_SELECTOR = 'selfServiceOfficeSelector',
	SELF_SERVICE_PRE_APPROVAL_DOCUMENT = 'selfServicePreApprovalDocument',
	SELF_SERVICE_PHONE_VERIFICATION = 'selfServicePhoneVerification',
	SELF_SERVICE_SHOW_OFFER = 'selfServiceShowOffer',
	SELF_SERVICE_REALTY_COUNTRY_RESTRICTED = 'selfServiceRealtyCountryRestricted',
	SELF_SERVICE_YEARLY_INCOME_LIABILITY_PERIODICITY = 'selfServiceYearlyIncomeLiabilityPeriodicity',
	SELF_SERVICE_DISABLE_CONSENT_DISCLAIMER = 'selfServiceDisableConsentDisclaimer',
	SELF_SERVICE_PRE_APPROVAL_DISPLAY_ACCEPTANCE_RULES = 'selfServicePreApprovalDisplayAcceptanceRules',
	SELF_SERVICE_SIMULATOR_USE_PAYMENT = 'selfServiceSimulatorUsePayment',
	SELF_SERVICE_ACQUISITION_SOURCE = 'selfServiceAcquisitionSource',
	SELF_SERVICE_SIMULATOR_USE_CALCULATION_CONFIGURATION = 'selfServiceSimulatorUseCalculationConfiguration',
	SELF_SERVICE_CLIENT_SIMULATOR_USE_REALTY_PRICE = 'selfServiceClientSimulatorUseRealtyPrice',
	SELF_SERVICE_USE_DOCUMENT_UPLOADED_STATUS = 'selfServiceUseDocumentUploadedStatus',
	SELF_SERVICE_NEGATIVE_PREAPPROVAL_CONTACT_FORM = 'selfServiceNegativePreapprovalContactForm',
	SELF_SERVICE_REGISTRATION_MUST_READ_PRIVACY_POLICY = 'selfServiceRegistrationMustReadPrivacyPolicy',
	SELF_SERVICE_REGISTRATION_MUST_READ_TERMS_AND_CONDITIONS = 'selfServiceRegistrationMustReadTermsAndConditions',
	SELF_SERVICE_USE_NEW_LANDING_PAGE = 'selfServiceUseNewLandingPage',
	SELF_SERVICE_NEW_SIGNUP_FLOW = 'selfServiceNewSignupFlow',
}

export interface FeatureFlags {
	[key: string]: boolean;
}
