import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DiscountConfiguration } from '@oper-client/shared/data-model';
import { AcceptanceRuleParameterValuePipe } from '../pipes/acceptance-rule-parameter-value.pipe';

@Pipe({
	name: 'discountDescription',
	pure: false, // otherwise translation doesn't happen when switching language
})
export class DiscountDescriptionPipe implements PipeTransform {
	constructor(private readonly parameterValueTypePipe: AcceptanceRuleParameterValuePipe, private readonly translatePipe: TranslatePipe) {}

	transform(discount: DiscountConfiguration): unknown {
		const translationParams = {};
		discount.parameters?.forEach((param) => {
			translationParams[param.name] = this.parameterValueTypePipe.transform(param.value, param.type);
		});
		translationParams['interestRate'] = this.parameterValueTypePipe.transform(discount.interestRate, { definition: 'percentage' });

		return this.translatePipe.transform(`ç.feature.offer.product.discounts.${discount.name}`, translationParams);
	}
}
