import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class GoogleAddressAutocompleteService {
	scriptLoaded$ = new BehaviorSubject(false);
	private scriptInjected = false;

	constructor(
		@Inject(CUSTOMER_INSIGHTS_CONFIG) readonly customerConfig: CustomerInsights,
		@Inject(DOCUMENT) private readonly document: Document
	) {}

	scriptLoaded(): Observable<boolean> {
		return this.scriptLoaded$.asObservable().pipe(
			filter((scriptLoaded) => scriptLoaded),
			take(1)
		);
	}

	injectAddressAutocompleteScript(): void {
		if (!this.scriptInjected) {
			const scriptElement = this.document.createElement('script');
			scriptElement.type = `application/javascript`;
			scriptElement.async = true;
			scriptElement.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.customerConfig.googleApiKey}`;
			scriptElement.addEventListener('load', () => {
				this.scriptLoaded$.next(true);
			});

			this.scriptInjected = true;
			this.document.body.insertAdjacentElement('beforeend', scriptElement);
		}
	}
}
