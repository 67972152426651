import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';

import { API_SERVICE, IApiService, IAuthService } from '@oper-client/shared/data-access';
import { IAM } from '@oper-client/shared/data-model';
import { BehaviorSubject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { EnvironmentLocaleFormatService } from '@oper-client/shared/util-formatting';

@Injectable()
export class MsAuthService implements IAuthService {
	_currentUser = new BehaviorSubject<IAM.User>(undefined);

	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		private readonly msalService: MsalService,
		private readonly msalBroadcastService: MsalBroadcastService,
		@Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
		private readonly localeFormatService: EnvironmentLocaleFormatService
	) {
		this.msalService.handleRedirectObservable().subscribe({
			next: () => {
				// Perform actions related to user accounts here
			},
			error: (error) => console.log(error),
		});

		this.msalBroadcastService.inProgress$
			.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
			.subscribe(() => {
				this.checkAndSetActiveAccount();
				const activeAccount = this.msalService.instance.getActiveAccount();
				if (activeAccount) {
					if (!this._currentUser.getValue()) {
						this.apiService.get('/api/me/').subscribe((apiUser) =>
							this._currentUser.next({
								...apiUser,
								...(activeAccount?.idTokenClaims?.['roles'] ? { role: activeAccount?.idTokenClaims?.['roles'][0] } : {}),
								fullname: activeAccount?.idTokenClaims?.['name'] || `${this.localeFormatService.formatFullName(apiUser)}`,
							} as IAM.User)
						);
					}
				} else if (this._currentUser.getValue() || this._currentUser.getValue() === undefined) {
					this._currentUser.next(null);
				}
			});
	}

	checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
		 */
		const accounts = this.msalService.instance.getAllAccounts();
		const activeAccount = this.msalService.instance.getActiveAccount();
		const latestAccount = accounts.length > 0 && accounts[0];

		if (activeAccount?.localAccountId !== latestAccount?.localAccountId) {
			this.msalService.instance.setActiveAccount(latestAccount);
		}
	}

	public getCurrentUser(): Observable<IAM.User> {
		return this._currentUser.asObservable().pipe(
			filter((value) => value !== undefined),
			take(1)
		);
	}

	updateCurrentUser(user: Partial<IAM.User>): Observable<IAM.User> {
		return this.apiService.patch(`/api/me/`, { bank_ids: user.bankIds });
	}

	public isAuthenticated() {
		return !!this._currentUser.getValue();
	}

	public logout(): Observable<void> {
		return this.msalService.logoutRedirect().pipe(tap(() => this._currentUser.next(null)));
	}

	public getPrivacyPolicy(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/privacy-policy/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	getTermsAndConditions(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/terms-and-conditions/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	login?(): void {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.msalService
					.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
					.subscribe((response: AuthenticationResult) => {
						this.msalService.instance.setActiveAccount(response.account);
					});
			} else {
				this.msalService.loginPopup().subscribe((response: AuthenticationResult) => {
					this.msalService.instance.setActiveAccount(response.account);
				});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
			} else {
				this.msalService.loginRedirect();
			}
		}
	}
}
