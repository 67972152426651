import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {
	BorrowerModes,
	CommonHistory,
	LoanRequest,
	LoanRequestComment,
	LoanRequestDecision,
	LoanRequestLoadParameters,
	PreapprovalResponse,
	StatusList,
} from '@oper-client/shared/data-model';

// API services
// Load
export const loadLoanRequests = createAction(
	'[LoanRequest] Load LoanRequests',
	props<{ parameters: LoanRequestLoadParameters; loadTotalAmount: boolean; removeAll?: boolean }>()
);
export const loadLoanRequestsSuccess = createAction(
	'[LoanRequest] Load LoanRequests Success',
	props<{ loanRequests: LoanRequest[]; loanRequestTotalCount?: number }>()
);
export const loadLoanRequestsFailure = createAction('[LoanRequest] Load LoanRequests Failure', props<{ error: HttpErrorResponse }>());

export const loadLoanRequestsPerStatus = createAction(
	'[LoanRequest] Load LoanRequests Per Status',
	props<{ parameters: LoanRequestLoadParameters; statuses: string[]; loadTotalAmount: boolean }>()
);
export const loadLoanRequestsPerStatusSuccess = createAction(
	'[LoanRequest] Load LoanRequests Per Status Success',
	props<{ loanRequestsPerStatus: { loanRequests: LoanRequest[]; loanRequestTotalCount?: number; status: string }[] }>()
);
export const loadLoanRequestsPerStatusFailure = createAction(
	'[LoanRequest] Load LoanRequests Per Status Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadLoanRequestsTotalAmountSuccess = createAction(
	'[LoanRequest] Load LoanRequests Total Amount Success',
	props<{ loanRequestTotalAmount?: number }>()
);
export const loadLoanRequestsTotalAmountFailed = createAction(
	'[LoanRequest] Load LoanRequests Total Amount Failed',
	props<{ error: HttpErrorResponse }>()
);

export const loadLoanRequestsTotalAmountPerStatus = createAction(
	'[LoanRequest] Load LoanRequests Total Amount Per Status',
	props<{ parameters: LoanRequestLoadParameters; statuses: string[]; loadTotalAmount: boolean }>()
);

export const loadLoanRequestsTotalAmountPerStatusSuccess = createAction(
	'[LoanRequest] Load LoanRequests Total Amount Per Status Success',
	props<{ loanRequestsTotalAmountPerStatus: Record<string, number>[] }>()
);

export const loadLoanRequestsTotalAmountPerStatusFailure = createAction(
	'[LoanRequest] Load LoanRequests Per Status Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadMoreLoanRequests = createAction('[LoanRequest] Load more LoanRequests', props<LoanRequestLoadParameters>());
export const loadMoreLoanRequestsSuccess = createAction(
	'[LoanRequest] Load more LoanRequests Success',
	props<{ loanRequests: LoanRequest[]; loanRequestCountPerStatus: { [key: string]: number } }>()
);
export const loadMoreLoanRequestsFailure = createAction(
	'[LoanRequest] Load more LoanRequests Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadLoanRequestStatuses = createAction('[LoanRequest] Load LoanRequest Statuses', props<{ loanRequestId: number }>());
export const loadLoanRequestStatusesSuccess = createAction(
	'[LoanRequest] Load LoanRequest Statuses Success',
	props<{ loanRequestStatuses: StatusList }>()
);
export const loadLoanRequestStatusesFailure = createAction(
	'[LoanRequest] Load LoanRequest Statuses Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loanRequestStatusChangePending = createAction(
	'[LoanRequest] Load LoanRequest Status Change Pending',
	props<{ loanRequestId: number }>()
);

export const resetLoanRequestStatus = createAction(
	'[LoanRequest] Reset LoanRequest Status',
	props<{ loanRequestId: number; loanRequestUpdate: Partial<LoanRequest> }>()
);

export const resetLoanRequestStatusById = createAction('[LoanRequest] Reset LoanRequest Status By Id', props<{ loanRequestId: number }>());

// Loan one LoanRequest fully
export const loadLoanRequest = createAction('[LoanRequest] Load LoanRequest', props<{ loanRequestId: number }>());
export const loadLoanRequestSuccess = createAction('[LoanRequest] Load LoanRequest Success', props<{ loanRequest: LoanRequest }>());
export const loadLoanRequestFailure = createAction(
	'[LoanRequest] Load current LoanRequests Failure',
	props<{ error: HttpErrorResponse }>()
);

// Create
export const createLoanRequest = createAction('[LoanRequest] Create LoanRequest', props<{ loanRequest: Partial<LoanRequest> }>());
export const createLoanRequestSuccess = createAction('[LoanRequest] Create LoanRequest Success', props<{ loanRequest: LoanRequest }>());
export const createLoanRequestFailure = createAction('[LoanRequest] Create LoanRequest Failure', props<{ error: HttpErrorResponse }>());

// Update
export const updateLoanRequest = createAction('[LoanRequest] Update LoanRequest', props<{ loanRequest: Update<LoanRequest> }>());
export const updateLoanRequestSuccess = createAction(
	'[LoanRequest] Update LoanRequest Success',
	props<{ loanRequest: Update<LoanRequest> }>()
);
export const updateLoanRequestFailure = createAction('[LoanRequest] Update LoanRequest Failure', props<{ error: HttpErrorResponse }>());

export const updateLoanRequestStatus = createAction(
	'[LoanRequest] Update LoanRequest Status',
	props<{ loanRequest: Update<LoanRequest> }>()
);
export const updateLoanRequestStatusSuccess = createAction(
	'[LoanRequest] Update LoanRequest Status Success',
	props<{ loanRequest: Update<LoanRequest> }>()
);
export const updateLoanRequestStatusFailure = createAction(
	'[LoanRequest] Update LoanRequest Status Failure',
	props<{ error: HttpErrorResponse }>()
);

// Local services
export const setCurrentLoanRequestId = createAction('[LoanRequest] Set current LoanRequest Id', props<{ loanRequestId: number }>());
export const resetCurrentLoanRequestId = createAction('[LoanRequest] Reset current LoanRequest Id');

export const setCurrentLoanRequestStatusId = createAction('[LoanRequest] Set current LoanRequest Status Id', props<{ statusId: number }>());
export const resetCurrentLoanRequestStatusId = createAction('[LoanRequest] Reset current LoanRequest Status Id');

export const assignAnalystToLoanRequest = createAction(
	'[LoanRequest] Assign Analyst To LoanRequest',
	props<{ loanRequestId: number; analystId: number | null }>()
);
export const assignAnalystToLoanRequestSuccess = createAction(
	'[LoanRequest] Assign Analyst To LoanRequest Success',
	props<{ loanRequest: LoanRequest }>()
);
export const assignAnalystToLoanRequestFailure = createAction(
	'[LoanRequest] Assign Analyst To LoanRequest Failure',
	props<{ error: HttpErrorResponse }>()
);

export const assignBrokerToLoanRequest = createAction(
	'[LoanRequest] Assign Broker To LoanRequest',
	props<{ loanRequestId: number; brokerId: number | null }>()
);
export const assignBrokerToLoanRequestSuccess = createAction(
	'[LoanRequest] Assign Broker To LoanRequest Success',
	props<{ loanRequest: LoanRequest }>()
);
export const assignBrokerToLoanRequestFailure = createAction(
	'[LoanRequest] Assign Broker To LoanRequest Failure',
	props<{ error: HttpErrorResponse }>()
);

export const updateAcquisitionSourceOfLoanRequest = createAction(
	'[LoanRequest] Update Acquisition Source Of LoanRequest',
	props<{ loanRequestId: number; acquisitionSourceId: number | null }>()
);
export const updateAcquisitionSourceOfLoanRequestSuccess = createAction(
	'[LoanRequest] Update Acquisition Source Of LoanRequest Success',
	props<{ loanRequest: LoanRequest }>()
);
export const updateAcquisitionSourceOfLoanRequestFailure = createAction(
	'[LoanRequest] Update Acquisition Source Of LoanRequest Failure',
	props<{ error: HttpErrorResponse }>()
);

// Create Decision
export const createDecision = createAction(
	'[LoanRequest] Create LoanRequest Decision',
	props<{ loanRequestId: number; decision: LoanRequestDecision }>()
);
export const createDecisionSuccess = createAction(
	'[LoanRequest] Create LoanRequest Decision Success',
	props<{ decision: LoanRequestDecision }>()
);
export const createDecisionFailure = createAction(
	'[LoanRequest] Create LoanRequest Decision Failure',
	props<{ error: HttpErrorResponse }>()
);

// Create Comment
export const createComment = createAction(
	'[LoanRequest] Create LoanRequest Comment',
	props<{ loanRequestId: number; comment: LoanRequestComment }>()
);
export const createCommentSuccess = createAction(
	'[LoanRequest] Create LoanRequest Comment Success',
	props<{ comment: LoanRequestComment }>()
);
export const createCommentFailure = createAction('[LoanRequest] Create LoanRequest Comment Failure', props<{ error: HttpErrorResponse }>());

// Load Decisions
export const loadDecisions = createAction('[LoanRequest] Load Decisions', props<{ loanRequestId: number }>());
export const loadDecisionsSuccess = createAction('[LoanRequest] Load Decisions Success', props<{ decisions: LoanRequestDecision[] }>());
export const loadDecisionsFailure = createAction('[LoanRequest] Load Decisions Failure', props<{ error: HttpErrorResponse }>());

// Load Comments
export const loadComments = createAction('[LoanRequest] Load Comments', props<{ loanRequestId: number }>());
export const loadCommentsSuccess = createAction('[LoanRequest] Load Comments Success', props<{ comments: LoanRequestComment[] }>());
export const loadCommentsFailure = createAction('[LoanRequest] Load Comments Failure', props<{ error: HttpErrorResponse }>());

// Load Histories
export const loadHistories = createAction(
	'[LoanRequest] Load Histories',
	props<{ loanRequestId: number; params: { log_level?: number } }>()
);
export const loadHistoriesSuccess = createAction('[LoanRequest] Load Histories Success', props<{ histories: CommonHistory[] }>());
export const loadHistoriesFailure = createAction('[LoanRequest] Load Histories Failure', props<{ error: HttpErrorResponse }>());

// Get preapproval
export const getPreapproval = createAction(
	'[LoanRequest] Get Preapproval',
	props<{ loanRequestId: number; borrowerMode: BorrowerModes }>()
);
export const getPreapprovalSuccess = createAction('[LoanRequest] Get Preapproval Success', props<{ preapproval: PreapprovalResponse }>());
export const getPreapprovalFailure = createAction('[LoanRequest] Get Preapproval Failure', props<{ error: HttpErrorResponse }>());

// load preapproval
export const loadPreapproval = createAction('[LoanRequest] Load Preapproval', props<{ loanRequestId: number }>());
export const loadPreapprovalSuccess = createAction('[LoanRequest] Load Preapproval Success', props<{ preapproval: PreapprovalResponse }>());
export const loadPreapprovalFailure = createAction('[LoanRequest] Load Preapproval Failure', props<{ error: HttpErrorResponse }>());

export const resetLoanRequestPreapproval = createAction('[LoanRequest] Reset Loan Request Pre-Approval');

export const toggleLoanRequestCancellation = createAction(
	'[LoanRequest] Toggle LoanRequest Cancellation',
	props<{ loanRequestId: number }>()
);
export const toggleLoanRequestCancellationSuccess = createAction(
	'[LoanRequest] Toggle LoanRequest Cancellation Success',
	props<{ loanRequest: LoanRequest }>()
);
export const toggleLoanRequestCancellationFailure = createAction(
	'[LoanRequest] Toggle LoanRequest Cancellation Failure',
	props<{ error: HttpErrorResponse }>()
);

export const clearLoanRequests = createAction('[LoanRequest] Clear LoanRequests');

export const clearState = createAction('[LoanRequest] Clear State');
