import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { CaseStyleService } from '@oper-client/shared/util-formatting';
import {
	ADDITIONAL_FINANCIAL_NEED_SERVICE,
	API_SERVICE,
	AUTH_SERVICE,
	CALCULATE_SERVICE,
	CLIENT_SERVICE,
	COMMISSION_SERVICE,
	CONTACT_DETAILS_SERVICE,
	CONTACT_FORM_SERVICE,
	FINANCIAL_ASSET_SERVICE,
	FINANCIAL_PLAN_CONFIG_SERVICE,
	FIRE_INSURANCE_SERVICE,
	IDENTITY_PROVIDER_SERVICE,
	INCOME_SERVICE,
	INTEGRATIONS_SERVICE,
	LIABILITY_INSURANCE_SERVICE,
	LIABILITY_SERVICE,
	LOAN_REQUEST_SERVICE,
	MORTGAGE_SIMULATOR_SERVICE,
	ADVISOR_SIMULATOR_SERVICE,
	OFFER_SERVICE,
	PRODUCT_DURATION_CONFIG_SERVICE,
	PRODUCT_PRICING_SERVICE,
	PRODUCT_SERVICE,
	PROPERTY_SERVICE,
	TASK_SERVICE,
	USER_SERVICE,
	LINK_SERVICE,
	MILESTONE_SERVICE,
} from '@oper-client/shared/data-access';
import { ApiV2Service } from './services/api-v2.service';
import { AdditionalFinancingNeedService } from './services/additional-financing-need.service';
import { ClientService } from './services/client.service';
import { FinancialAssetService } from './services/financial-asset.service';
import { IncomeService } from './services/income.service';
import { LiabilityService } from './services/liability.service';
import { CalculateService } from './services/calculate.service';
import { CommissionService } from './services/commission.service';
import { LiabilityInsuranceService } from './services/liability-insurance.service';
import { RealtyService } from './services/realty.service';
import { ContactDetailsService } from './services/contact-details.service';
import { UserService } from './services/user.service';
import { ProductService } from './services/product.service';
import { OfferService } from './services/offer.service';
import { LoanRequestService } from './services/loan-request.service';
import { TaskService } from './services/task.service';
import { FireInsuranceService } from './services/fire-insurance.service';
import { IntegrationsService } from './services/integrations.service';
import { IdentityProviderService } from './services/identity-provider.service';
import { ProductDurationConfigService } from './services/product-duration-config.service';
import { MortgageSimulatorService } from './services/mortgage-simulator.service';
import { AdvisorSimulatorService } from './services/advisor-simulator.service';
import { ContactFormService } from './services/contact-form.service';
import { ProductPricingService } from './services/product-pricing.service';
import { FinancialPlanConfigService } from './services/financial-plan-config.service';
import { LinkService } from './services/link.service';
import { CONFIGURATION_PORTAL_PRICING_SERVICE, PricingService } from './services/pricing.service';
import { MilestoneService } from './services/milestone.service';

@NgModule()
export class ApiV2Module {
	static forRoot(config: { authService: any; deps: any[] }): ModuleWithProviders<ApiV2Module> {
		return {
			ngModule: ApiV2Module,
			providers: [
				{ provide: API_SERVICE, useClass: ApiV2Service, deps: [HttpClient, CUSTOMER_INSIGHTS_CONFIG] },
				{ provide: AUTH_SERVICE, useClass: config.authService, deps: config.deps },
				{ provide: ADDITIONAL_FINANCIAL_NEED_SERVICE, useClass: AdditionalFinancingNeedService, deps: [API_SERVICE] },
				{ provide: CLIENT_SERVICE, useClass: ClientService, deps: [API_SERVICE] },
				{ provide: FINANCIAL_ASSET_SERVICE, useClass: FinancialAssetService, deps: [API_SERVICE] },
				{ provide: INCOME_SERVICE, useClass: IncomeService, deps: [API_SERVICE] },
				{ provide: LIABILITY_SERVICE, useClass: LiabilityService, deps: [API_SERVICE] },
				{ provide: CALCULATE_SERVICE, useClass: CalculateService, deps: [API_SERVICE] },
				{ provide: COMMISSION_SERVICE, useClass: CommissionService, deps: [API_SERVICE] },
				{ provide: LIABILITY_INSURANCE_SERVICE, useClass: LiabilityInsuranceService, deps: [API_SERVICE] },
				{ provide: PROPERTY_SERVICE, useClass: RealtyService, deps: [API_SERVICE] },
				{ provide: CONTACT_DETAILS_SERVICE, useClass: ContactDetailsService, deps: [API_SERVICE] },
				{ provide: USER_SERVICE, useClass: UserService, deps: [API_SERVICE] },
				{ provide: PRODUCT_SERVICE, useClass: ProductService, deps: [API_SERVICE] },
				{ provide: OFFER_SERVICE, useClass: OfferService, deps: [API_SERVICE, CaseStyleService] },
				{ provide: LOAN_REQUEST_SERVICE, useClass: LoanRequestService, deps: [API_SERVICE] },
				{ provide: TASK_SERVICE, useClass: TaskService, deps: [API_SERVICE] },
				{ provide: FIRE_INSURANCE_SERVICE, useClass: FireInsuranceService, deps: [API_SERVICE] },
				{ provide: INTEGRATIONS_SERVICE, useClass: IntegrationsService, deps: [API_SERVICE] },
				{ provide: IDENTITY_PROVIDER_SERVICE, useClass: IdentityProviderService, deps: [API_SERVICE] },
				{ provide: PRODUCT_DURATION_CONFIG_SERVICE, useClass: ProductDurationConfigService, deps: [API_SERVICE] },
				{ provide: MORTGAGE_SIMULATOR_SERVICE, useClass: MortgageSimulatorService, deps: [API_SERVICE] },
				{ provide: ADVISOR_SIMULATOR_SERVICE, useClass: AdvisorSimulatorService, deps: [API_SERVICE] },
				{ provide: CONTACT_FORM_SERVICE, useClass: ContactFormService, deps: [API_SERVICE] },
				{ provide: PRODUCT_PRICING_SERVICE, useClass: ProductPricingService, deps: [API_SERVICE] },
				{ provide: FINANCIAL_PLAN_CONFIG_SERVICE, useClass: FinancialPlanConfigService, deps: [API_SERVICE] },
				{ provide: LINK_SERVICE, useClass: LinkService, deps: [API_SERVICE] },
				{ provide: CONFIGURATION_PORTAL_PRICING_SERVICE, useClass: PricingService, deps: [API_SERVICE] },
				{ provide: MILESTONE_SERVICE, useClass: MilestoneService, deps: [API_SERVICE] },
			],
		};
	}
}
