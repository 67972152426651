import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { API_SERVICE, IApiService, IAdvisorSimulatorService, CustomHttpUrlEncodingCodec } from '@oper-client/shared/data-access';
import { BaseProductDiscounts, BorrowerUploadDocumentType, mapDtoToOffer, Offer, Product, ProductSearchFilterParams, Simulator } from '@oper-client/shared/data-model';

@Injectable()
export class AdvisorSimulatorService implements IAdvisorSimulatorService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	calculateSimulation(payload: Simulator.CalculateSimulationPayload): Observable<Simulator.CalculatedSimulationResult> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/`, payload);
	}

	createSimulation(request?: Partial<Simulator.Simulation>): Observable<Simulator.Simulation> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/simulations/`, request);
	}

	updateSimulation(simulationId: number, request: Partial<Simulator.Simulation>): Observable<Simulator.Simulation> {
		return this.apiService.patch(`/api/simulators/mortgage/advisor/simulations/${simulationId}/`, request);
	}

	loadSimulations(): Observable<Simulator.Simulation[]> {
		return this.apiService.get(`/api/simulators/mortgage/advisor/simulations/`);
	}

	loadSimulation(simulationId: number): Observable<Simulator.Simulation> {
		return this.apiService.get(`/api/simulators/mortgage/advisor/simulations/${simulationId}/`);
	}

	removeSimulation(simulationId: number): Observable<void> {
		return this.apiService.delete(`/api/simulators/mortgage/advisor/simulations/${simulationId}/`);
	}

	cleanUpSimulations(): Observable<void> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/simulations/clean-up/`);
	}

	loadProducts(payload: Partial<Simulator.SearchProductsPayload>, filters?: ProductSearchFilterParams): Observable<Product[]> {
		let httpParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

		if (filters?.creditProvider) {
			httpParams = httpParams.append('credit_provider', <any>filters.creditProvider);
		}

		if (filters?.loanType) {
			httpParams = httpParams.append('loan_type', <any>filters.loanType);
		}

		if (filters?.duration) {
			httpParams = httpParams.append('min_duration', filters.duration);
			httpParams = httpParams.append('max_duration', filters.duration);
		}

		if (filters?.variability && !Array.isArray(filters?.variability)) {
			httpParams = httpParams.append('is_fixed_variability', filters.variability === 'fix');
		}

		if (filters?.selectDefaultDiscounts) {
			httpParams = httpParams.append('select_default_discounts', filters.selectDefaultDiscounts);
		}

		return this.apiService
			.post(`/api/simulators/mortgage/advisor/products/`, payload, httpParams)
			.pipe(map((response) => response.products));
	}

	updateOffer(payload: Partial<Simulator.UpdateOfferPayload>): Observable<Partial<Offer>> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/offer/`, payload).pipe(map((offer) => mapDtoToOffer(offer)));
	}

	loadOffers(payload: Partial<Simulator.LoadOffersPayload>): Observable<Partial<Offer>[]> {
		return this.apiService
			.post(`/api/simulators/mortgage/advisor/offers/`, payload)
			.pipe(map((offers) => offers.map((offer) => mapDtoToOffer(offer))));
	}

	loadDiscounts(payload: Partial<Simulator.LoadDiscountsPayload>, selectDefaultDiscounts = false): Observable<BaseProductDiscounts[]> {
		let httpParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
		httpParams = httpParams.append('select_default_discounts', selectDefaultDiscounts);

		return this.apiService.post(`/api/simulators/mortgage/advisor/discounts/`, payload, httpParams);
	}

	convertSimulationToLoanRequest(
		payload: Simulator.ConvertToLoanRequestPayload
	): Observable<Simulator.ConvertSimulationToLoanRequestResponse> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/convert/`, payload);
	}

	loadBorrowerDocumentTypes(): Observable<BorrowerUploadDocumentType[]> {
		return this.apiService.get(`/api/simulators/mortgage/advisor/client-upload-document-types/`);
	}
}
