import { Observable } from 'rxjs';
import { IAM, PrivacyPolicy, JoinDetails } from '@oper-client/shared/data-model';
import { InjectionToken } from '@angular/core';
import { HttpParams } from '@angular/common/http';

export const AUTH_SERVICE = new InjectionToken<IAuthService>('AUTH_SERVICE');

export interface IAuthService {
	isInitialized?(): Observable<boolean>;

	setTokens?(tokens): void;

	refreshToken?(): Observable<any>;

	getAccessToken?(): string;

	getRefreshToken?(): string;

	unsetTokens?(): void;

	getCurrentUser(): Observable<IAM.User>;

	updateCurrentUser(user: Partial<IAM.User>): Observable<IAM.User>;

	isAuthenticated(): boolean;

	login?(request?: IAM.UserCredentials): Observable<IAM.JwtTokens> | void;

	logout(): Observable<void>;

	forgotPassword?(email: string): Observable<any>;

	resetPassword?(body: IAM.ResetPassword): Observable<any>;

	resetPassword2Fa?(body: IAM.ResetPassword): Observable<any>;

	checkResetPasswordToken?(token: string): Observable<any>;

	activateUser?(token: string): Observable<void>;

	verifyUser?(code: string, token: string): Observable<void>;

	validateCredentials?(
		username: string,
		password: string
	): Observable<{
		email: string;
		token: string;
		verified: boolean;
	}>;

	activateOTP?(token: string): Observable<{
		token: string;
	}>;

	verifyOTP?(token: string, code: string): Observable<IAM.JwtTokens>;

	getPrivacyPolicy(token: string, language: string, alternativeLanguage: string, params?: HttpParams): Observable<PrivacyPolicy>;

	getTermsAndConditions(token: string, language: string, alternativeLanguage: string, params?: HttpParams): Observable<PrivacyPolicy>;

	createUser?(userData: Partial<JoinDetails>): Observable<IAM.JwtTokens>;

	updateProfile?(profileData: Partial<JoinDetails>): Observable<{ token: string }>;

	inviteUser?(userData: Partial<JoinDetails>): Observable<IAM.JwtTokens>;

	checkInviteToken?(token: string): Observable<any>;
}
