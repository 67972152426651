import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
	Resource,
	ActionState,
	initialActionState,
	setActionState,
	ActionTypes,
	LoanRequest,
	LoanRequestDecision,
	LoanRequestComment,
	CommonHistory,
	PreapprovalResponse,
} from '@oper-client/shared/data-model';
import * as LoanRequestActions from './loan-request.actions';
import { deepClone } from '@oper-client/shared/util-object';

export const LOAN_REQUEST_ENTITY_KEY = 'loan-request';

export interface LoanRequestState extends EntityState<LoanRequest> {
	loanRequestTotalCount?: number;
	loanRequestTotalAmount?: number;
	currentLoanRequestId?: number;
	currentLoanRequestStatusId?: number;
	loanRequestStatuses: Resource[];
	loanRequestStatusChangePending: boolean;
	decisions: LoanRequestDecision[];
	comments: LoanRequestComment[];
	histories: CommonHistory[];
	actions: LoanRequestActionsState;
	filters?: { [key: string]: string };
	search?: string;
	loanRequestCountsPerStatus?: Record<string, number>;
	loanRequestTotalAmountPerStatus?: Record<string, number>;
	preapproval: PreapprovalResponse;
	loanRequestFirstLoaded: boolean;
}

export type LoanRequestActionTypes =
	| 'loadLoanRequest'
	| 'loadMoreLoanRequest'
	| 'loadLoanRequestsPerStatus'
	| 'loadLoanRequestsTotalAmountPerStatus'
	| 'loadLoanRequestStatuses'
	| 'loadLoanRequestList'
	| 'loadLoanRequestTotalAmount'
	| 'loadMoreLoanRequestList'
	| 'createLoanRequest'
	| 'updateLoanRequest'
	| 'updateLoanRequestStatus'
	| 'assignAnalyst'
	| 'assignBroker'
	| 'updateAcquisitionSource'
	| 'toggleCancellation'
	| 'createDecision'
	| 'createComment'
	| 'loadDecisions'
	| 'loadComments'
	| 'loadHistories'
	| 'getPreapproval'
	| 'loadPreapproval';
export type LoanRequestActionsState = Record<LoanRequestActionTypes, ActionState>;

export const loanRequestAdapter: EntityAdapter<LoanRequest> = createEntityAdapter<LoanRequest>();

export const initialState: LoanRequestState = loanRequestAdapter.getInitialState({
	decisions: [],
	comments: [],
	histories: [],
	loanRequestStatuses: [],
	loanRequestStatusChangePending: false,
	loanRequestCountsPerStatus: {},
	loanRequestTotalAmountPerStatus: {},
	loanRequestFirstLoaded: false,
	actions: {
		loadLoanRequest: initialActionState,
		loadMoreLoanRequest: initialActionState,
		loadLoanRequestsPerStatus: initialActionState,
		loadLoanRequestsTotalAmountPerStatus: initialActionState,
		loadLoanRequestStatuses: initialActionState,
		loadLoanRequestList: initialActionState,
		loadLoanRequestTotalAmount: initialActionState,
		loadMoreLoanRequestList: initialActionState,
		createLoanRequest: initialActionState,
		updateLoanRequest: initialActionState,
		updateLoanRequestStatus: initialActionState,
		assignAnalyst: initialActionState,
		assignBroker: initialActionState,
		toggleCancellation: initialActionState,
		createDecision: initialActionState,
		createComment: initialActionState,
		loadDecisions: initialActionState,
		loadComments: initialActionState,
		loadHistories: initialActionState,
		updateAcquisitionSource: initialActionState,
		getPreapproval: initialActionState,
		loadPreapproval: initialActionState,
	},
	preapproval: { acceptanceRules: [], approved: null },
});

function setActionStates(
	actionState: LoanRequestActionsState,
	action: LoanRequestActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): LoanRequestActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const loanRequestReducer = createReducer(
	initialState,

	on(LoanRequestActions.loadLoanRequests, (state, { parameters, removeAll }) =>
		removeAll
			? loanRequestAdapter.removeAll({
					...state,
					actions: setActionStates(state.actions, 'loadLoanRequestList', ActionTypes.loading),
					loanRequestTotalCount: null,
					loanRequestTotalAmount: null,
					search: parameters.search,
					filters: parameters.filters,
				})
			: {
					...state,
					actions: setActionStates(state.actions, 'loadLoanRequestList', ActionTypes.loading),
					loanRequestTotalCount: null,
					loanRequestTotalAmount: null,
					search: parameters.search,
					filters: parameters.filters,
				}
	),
	on(LoanRequestActions.loadLoanRequestsSuccess, (state, { loanRequests, loanRequestTotalCount }) =>
		loanRequestAdapter.setAll(loanRequests, {
			...state,
			actions: setActionStates(state.actions, 'loadLoanRequestList', ActionTypes.success),
			loanRequestTotalCount: loanRequestTotalCount,
		})
	),
	on(LoanRequestActions.loadLoanRequestsPerStatus, (state, { parameters }) =>
		loanRequestAdapter.removeAll({
			...state,
			loanRequestTotalAmountPerStatus: {},
			loanRequestCountsPerStatus: {},
			actions: setActionStates(state.actions, 'loadLoanRequestsPerStatus', ActionTypes.loading),
			search: parameters.search,
			filters: parameters.filters,
		})
	),
	on(LoanRequestActions.loadLoanRequestsPerStatusSuccess, (state, { loanRequestsPerStatus }) =>
		loanRequestAdapter.setAll(
			loanRequestsPerStatus.reduce((arr, item) => [...arr, ...item.loanRequests], []),
			{
				...state,
				actions: setActionStates(state.actions, 'loadLoanRequestsPerStatus', ActionTypes.success),
				loanRequestCountsPerStatus: loanRequestsPerStatus.reduce(
					(obj, item) => ({ ...obj, [item.status]: item.loanRequestTotalCount }),
					{}
				),
			}
		)
	),

	on(LoanRequestActions.resetLoanRequestStatusById, (state, { loanRequestId }) => {
		const entities = deepClone(state.entities);
		entities[loanRequestId].status = { id: 1 };
		return { ...state, entities };
	}),

	on(LoanRequestActions.loadLoanRequestsPerStatusFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestsPerStatus', ActionTypes.failure, error),
	})),

	on(LoanRequestActions.loadLoanRequestsTotalAmountPerStatus, (state, { parameters }) => ({
		...state,
		loanRequestTotalAmountPerStatus: {},
		actions: setActionStates(state.actions, 'loadLoanRequestsTotalAmountPerStatus', ActionTypes.loading),
	})),
	on(LoanRequestActions.loadLoanRequestsTotalAmountPerStatusSuccess, (state, { loanRequestsTotalAmountPerStatus }) => ({
		...state,
		loanRequestTotalAmountPerStatus: loanRequestsTotalAmountPerStatus.reduce((obj, item) => ({ ...obj, ...item }), {}),
		actions: setActionStates(state.actions, 'loadLoanRequestsTotalAmountPerStatus', ActionTypes.success),
	})),
	on(LoanRequestActions.loadLoanRequestsTotalAmountPerStatusFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestsTotalAmountPerStatus', ActionTypes.failure, error),
	})),

	on(LoanRequestActions.loadLoanRequestsTotalAmountSuccess, (state, { loanRequestTotalAmount }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestTotalAmount', ActionTypes.success),
		loanRequestTotalAmount: loanRequestTotalAmount,
	})),

	on(LoanRequestActions.loadLoanRequestsTotalAmountFailed, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestTotalAmount', ActionTypes.failure, error),
		loanRequestTotalAmount: null,
	})),
	on(LoanRequestActions.loadLoanRequestsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestList', ActionTypes.failure, error),
	})),

	on(LoanRequestActions.loadMoreLoanRequests, (state, { search, filters }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMoreLoanRequestList', ActionTypes.loading),
	})),
	on(LoanRequestActions.loadMoreLoanRequestsSuccess, (state, { loanRequests, loanRequestCountPerStatus }) =>
		loanRequestAdapter.upsertMany(loanRequests, {
			...state,
			loanRequestCountsPerStatus: { ...state.loanRequestCountsPerStatus, ...loanRequestCountPerStatus },
			actions: setActionStates(state.actions, 'loadMoreLoanRequestList', ActionTypes.success),
		})
	),
	on(LoanRequestActions.loadMoreLoanRequestsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMoreLoanRequestList', ActionTypes.failure, error),
	})),

	// LoanRequest Statuses
	on(LoanRequestActions.loadLoanRequestStatuses, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestStatuses', ActionTypes.loading),
	})),
	on(LoanRequestActions.loadLoanRequestStatusesSuccess, (state, { loanRequestStatuses }) => ({
		...state,
		loanRequestStatuses,
		actions: setActionStates(state.actions, 'loadLoanRequestStatuses', ActionTypes.success),
	})),
	on(LoanRequestActions.loadLoanRequestStatusesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestStatuses', ActionTypes.failure, error),
	})),

	on(LoanRequestActions.loanRequestStatusChangePending, (state) => ({
		...state,
		loanRequestStatusChangePending: true,
	})),

	// Load one
	on(LoanRequestActions.loadLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequest', ActionTypes.loading),
	})),

	on(LoanRequestActions.loadLoanRequestSuccess, (state, { loanRequest }) =>
		loanRequestAdapter.upsertOne(loanRequest, {
			...state,
			actions: setActionStates(state.actions, 'loadLoanRequest', ActionTypes.success),

			/**
			 *
			 * NOTE:
			 * This is a mid-long term temporary change until we find a solution to the
			 * loadLoanRequest action types update on each successful fetch what triggers by polling
			 *
			 *  */
			...(state.loanRequestFirstLoaded ? {} : { loanRequestFirstLoaded: true }),

			loanRequestStatusChangePending: calculateStatusChangePending(
				state.loanRequestStatusChangePending,
				state.entities[loanRequest.id]?.status?.id,
				loanRequest.status.id
			),
		})
	),

	on(LoanRequestActions.loadLoanRequestFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequest', ActionTypes.failure, error),
	})),
	// Create
	on(LoanRequestActions.createLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createLoanRequest', ActionTypes.loading),
	})),
	on(LoanRequestActions.createLoanRequestSuccess, (state, { loanRequest }) => ({
		...loanRequestAdapter.addOne(loanRequest, {
			...state,
			actions: setActionStates(state.actions, 'createLoanRequest', ActionTypes.success),
			currentLoanRequestId: loanRequest.id,
		}),
	})),
	on(LoanRequestActions.createLoanRequestFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createLoanRequest', ActionTypes.failure, error),
	})),
	// Update
	on(LoanRequestActions.updateLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLoanRequest', ActionTypes.loading),
	})),
	on(LoanRequestActions.updateLoanRequestSuccess, (state, { loanRequest }) =>
		loanRequestAdapter.updateOne(loanRequest, {
			...state,
			actions: setActionStates(state.actions, 'updateLoanRequest', ActionTypes.success),
		})
	),
	on(LoanRequestActions.updateLoanRequestFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLoanRequest', ActionTypes.failure, error),
	})),

	// Update status
	on(LoanRequestActions.updateLoanRequestStatus, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLoanRequestStatus', ActionTypes.loading),
	})),
	on(LoanRequestActions.updateLoanRequestStatusSuccess, (state, { loanRequest }) => {
		const oldStatus: string = state.entities[loanRequest.id].status.definition;
		const newStatus: string = loanRequest.changes.status.definition;
		const loanAmount: number = state.entities[loanRequest.id].loanAmount;
		return loanRequestAdapter.updateOne(loanRequest, {
			...state,
			loanRequestCountsPerStatus: {
				...state.loanRequestCountsPerStatus,
				[newStatus]: state.loanRequestCountsPerStatus[newStatus] + 1,
				[oldStatus]: state.loanRequestCountsPerStatus[oldStatus] - 1,
			},
			loanRequestTotalAmountPerStatus: {
				...state.loanRequestTotalAmountPerStatus,
				[newStatus]: state.loanRequestTotalAmountPerStatus[newStatus] + loanAmount,
				[oldStatus]: state.loanRequestTotalAmountPerStatus[oldStatus] - loanAmount,
			},
			actions: setActionStates(state.actions, 'updateLoanRequestStatus', ActionTypes.success),
		});
	}),
	on(LoanRequestActions.updateLoanRequestStatusFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLoanRequestStatus', ActionTypes.failure, error),
	})),

	// Set current LoanRequest Id
	on(LoanRequestActions.setCurrentLoanRequestId, (state, { loanRequestId }) => ({
		...state,
		currentLoanRequestId: loanRequestId,
	})),
	on(LoanRequestActions.resetCurrentLoanRequestId, (state) => ({
		...state,
		currentLoanRequestId: null,
		decisions: [],
		comments: [],
		histories: [],
	})),

	// Set current LoanRequest Status
	on(LoanRequestActions.setCurrentLoanRequestStatusId, (state, { statusId }) => ({
		...state,
		currentLoanRequestStatusId: statusId,
	})),
	on(LoanRequestActions.resetCurrentLoanRequestStatusId, (state) => ({
		...state,
		currentLoanRequestStatusId: null,
	})),

	// Set Analyst Id
	on(LoanRequestActions.assignAnalystToLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'assignAnalyst', ActionTypes.loading),
	})),
	on(LoanRequestActions.assignAnalystToLoanRequestSuccess, (state, { loanRequest }) =>
		loanRequestAdapter.upsertOne(loanRequest, {
			...state,
			actions: setActionStates(state.actions, 'assignAnalyst', ActionTypes.success),
		})
	),
	on(LoanRequestActions.assignAnalystToLoanRequestFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'assignAnalyst', ActionTypes.failure, error),
	})),

	// Set Broker Id
	on(LoanRequestActions.assignBrokerToLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'assignBroker', ActionTypes.loading),
	})),
	on(LoanRequestActions.assignBrokerToLoanRequestSuccess, (state, { loanRequest }) =>
		loanRequestAdapter.upsertOne(loanRequest, {
			...state,
			actions: setActionStates(state.actions, 'assignBroker', ActionTypes.success),
		})
	),
	on(LoanRequestActions.assignBrokerToLoanRequestFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'assignBroker', ActionTypes.failure, error),
	})),

	// Set Acquisition Source Id
	on(LoanRequestActions.updateAcquisitionSourceOfLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateAcquisitionSource', ActionTypes.loading),
	})),
	on(LoanRequestActions.updateAcquisitionSourceOfLoanRequestSuccess, (state, { loanRequest }) =>
		loanRequestAdapter.upsertOne(loanRequest, {
			...state,
			actions: setActionStates(state.actions, 'updateAcquisitionSource', ActionTypes.success),
		})
	),
	on(LoanRequestActions.updateAcquisitionSourceOfLoanRequestFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateAcquisitionSource', ActionTypes.failure, error),
	})),

	// Toogle cancellation
	on(LoanRequestActions.toggleLoanRequestCancellation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'toggleCancellation', ActionTypes.loading),
	})),
	on(LoanRequestActions.toggleLoanRequestCancellationSuccess, (state, { loanRequest }) =>
		loanRequestAdapter.upsertOne(loanRequest, {
			...state,
			actions: setActionStates(state.actions, 'toggleCancellation', ActionTypes.success),
		})
	),
	on(LoanRequestActions.toggleLoanRequestCancellationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'toggleCancellation', ActionTypes.failure, error),
	})),

	// Create Decision
	on(LoanRequestActions.createDecision, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createDecision', ActionTypes.loading),
	})),
	on(LoanRequestActions.createDecisionSuccess, (state, { decision }) => ({
		...state,
		decisions: [...state.decisions, decision],
		actions: setActionStates(state.actions, 'createDecision', ActionTypes.success),
	})),
	on(LoanRequestActions.createDecisionFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createDecision', ActionTypes.failure, error),
	})),

	// Create Comment
	on(LoanRequestActions.createComment, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createComment', ActionTypes.loading),
	})),
	on(LoanRequestActions.createCommentSuccess, (state, { comment }) => ({
		...state,
		comments: [...state.comments, comment],
		actions: setActionStates(state.actions, 'createComment', ActionTypes.success),
	})),
	on(LoanRequestActions.createCommentFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createComment', ActionTypes.failure, error),
	})),

	// Load Decisions
	on(LoanRequestActions.loadDecisions, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDecisions', ActionTypes.loading),
	})),
	on(LoanRequestActions.loadDecisionsSuccess, (state, { decisions }) => ({
		...state,
		decisions: decisions,
		actions: setActionStates(state.actions, 'loadDecisions', ActionTypes.success),
	})),
	on(LoanRequestActions.loadDecisionsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDecisions', ActionTypes.failure, error),
	})),

	// Load Comments
	on(LoanRequestActions.loadComments, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadComments', ActionTypes.loading),
	})),
	on(LoanRequestActions.loadCommentsSuccess, (state, { comments }) => ({
		...state,
		comments: comments,
		actions: setActionStates(state.actions, 'loadComments', ActionTypes.success),
	})),
	on(LoanRequestActions.loadCommentsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadComments', ActionTypes.failure, error),
	})),

	// Load Histories
	on(LoanRequestActions.loadHistories, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadHistories', ActionTypes.loading),
	})),
	on(LoanRequestActions.loadHistoriesSuccess, (state, { histories }) => ({
		...state,
		histories: histories,
		actions: setActionStates(state.actions, 'loadHistories', ActionTypes.success),
	})),
	on(LoanRequestActions.loadHistoriesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadHistories', ActionTypes.failure, error),
	})),

	// Get Preapproval
	on(LoanRequestActions.getPreapproval, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'getPreapproval', ActionTypes.loading),
	})),
	on(LoanRequestActions.getPreapprovalSuccess, (state, { preapproval }) => ({
		...state,
		preapproval,
		actions: setActionStates(state.actions, 'getPreapproval', ActionTypes.success),
	})),
	on(LoanRequestActions.getPreapprovalFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'getPreapproval', ActionTypes.failure, error),
	})),

	// Load Preapproval
	on(LoanRequestActions.loadPreapproval, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadPreapproval', ActionTypes.loading),
	})),
	on(LoanRequestActions.loadPreapprovalSuccess, (state, { preapproval }) => ({
		...state,
		preapproval,
		actions: setActionStates(state.actions, 'loadPreapproval', ActionTypes.success),
	})),
	on(LoanRequestActions.loadPreapprovalFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadPreapproval', ActionTypes.failure, error),
	})),

	on(LoanRequestActions.resetLoanRequestPreapproval, (state) => ({
		...state,
		preapproval: initialState.preapproval,
	})),

	// Clear loan requests
	on(LoanRequestActions.clearLoanRequests, (state) =>
		loanRequestAdapter.removeAll({ ...state, currentLoanRequestId: null, currentLoanRequestStatusId: null })
	),
	on(LoanRequestActions.clearState, () => loanRequestAdapter.getInitialState(initialState))
);

export function reducer(state: LoanRequestState | undefined, action: Action) {
	return loanRequestReducer(state, action);
}

export const getSelectedLoanRequestId = (state: LoanRequestState) => state.currentLoanRequestId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = loanRequestAdapter.getSelectors();

// select the array of user ids
export const selectLoanRequestIds = selectIds;

// select the dictionary of user entities
export const selectLoanRequestEntities = selectEntities;

// select the array of users
export const selectAllLoanRequest = selectAll;

// select the total user count
export const selectLoanRequestTotal = selectTotal;

function calculateStatusChangePending(statusChangePending: boolean, currentStatusId: number, nextStatusId: number): boolean {
	return statusChangePending && currentStatusId === nextStatusId;
}
