import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UtilFormattingModule } from '@oper-client/shared/util-formatting';

import { OutsideClickDirective } from './directives/outside-click.directive';
import { DragDropUiModule } from './drag-drop-ui/drag-drop.module';
import { FileUploadUiModule } from './file-upload-ui/file-upload-ui.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarSecondaryComponent } from './components/sidebar-secondary/sidebar-secondary.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationComponent } from './components/notifications/notification/notification.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { ModalComponent } from './components/modal/modal.component';
import { HeaderComponent } from './components/header/header.component';
import { ChipComponent } from './components/chip/chip.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { SelectComponent } from './components/select/select.component';
import { AlertComponent } from './components/alert/alert.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonSmartComponent } from './components/button-smart/button-smart.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { CardComponent } from './components/card/card.component';
import { ScanbotComponent } from './components/scanbot/scanbot.component';
import { ScanDocumentComponent } from './components/scan-document/scan-document.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';
import { TooltipIndicatorComponent } from './components/tooltip-indicator/tooltip-indicator.component';
import { TooltipPaneComponent } from './components/tooltip-pane/tooltip-pane.component';
import { ButtonSwitch } from './components/button-switch/button-switch.component';
import { ToggleSwitch } from './components/toggle-switch/toggle-switch.component';
import { LearnMoreTextComponent } from './components/learn-more-text/learn-more-text.component';
import { CardDrawingComponent } from './components/card-drawing/card-drawing.component';
import { PoweredByComponent } from './components/powered-by/powered-by.component';
import { ListComponent } from './components/list/list.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { ButtonArrowComponent } from './components/button-arrow/button-arrow.component';
import { PasswordValidationComponent } from './components/password-validation/password-validation.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { SafeHtmlPile } from './pipes/safe-pipe';
import { OperRouterLinkDirective } from './directives/oper-router-link.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HyperlinkComponent } from './components/hyperlink/hyperlink.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { HyperlinkRedirectComponent } from './components/hyperlink-redirect/hyperlink-redirect.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProgressOverlayComponent } from './components/progress-overlay/progress-overlay.component';
import { NDigitDecimaNumberDirective } from './directives/n-digit-decimal-number.directive';
import { GtmModule } from '@oper-client/shared/tracking/gtm';
import { ConsentDisclaimerComponent } from './components/consent-disclaimer/consent-disclaimer.component';
import { PanelComponent } from './components/panel/panel.component';
import { TabsModule } from './components/tabs/tabs.module';
import { DeploymentAnnouncementComponent } from './components/deployment-announcement/deployment-announcement.component';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { ChartsModule } from './components/charts/charts.module';
import { DurationSelectorButtonsComponent } from './components/duration-selector-buttons/duration-selector-buttons.component';
import { CollapsiblePanelComponent } from './components/collapsible-panel/collapsible-panel.component';
import { NumberToDecimalDirective } from './directives/number-to-decimal.directive';
import { CustomTranslationComponent } from './components/custom-translation/custom-translation.component';
import { ContenteditableValueAccessorDirective } from './directives/contenteditable-value-accessor.directive';
import { CollapsibleModule } from './components/collapsible/collapsible.module';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { ShowTooltipIfTruncatedDirective } from './directives/show-tooltip-if-truncated.directive';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AmountDisplaymentComponent } from './components/amount-displayment/amount-displayment.component';
import { ProductDisplaymentComponent } from './components/product-displayment/product-displayment.component';
import { LabelComponent } from './components/label/label.component';
import { ErrorIndicatorComponent } from './components/error-indicator/error-indicator.component';
import { StatusActionButtonComponent } from './components/status-action-button/status-action-button.component';
import { CollapseToggleComponent } from './components/collapse-toggle/collapse-toggle.component';
import { VariabilitySelectComponent } from './components/variability-select/variability-select.component';
import { PercentageDisplaymentComponent } from './components/percentage-displayment/percentage-displayment.component';
import { CurrencyDisplaymentComponent } from './components/currency-displayment/currency-displayment.component';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { InputPercentageComponent } from './components/input-percentage/input-percentage.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { AddressPreviewComponent } from './components/address-preview/address-preview.component';
import { AddressPreviewPipe } from './pipes/address-preview.pipe';
import { FontawesomeIconComponent } from './components/fontawesome-icon/fontawesome-icon.component';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';
import { ClickableComponent } from './components/clickable/clickable.component';
import { AmountProgressBarComponent } from './components/amount-progress-bar/amount-progress-bar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { InformationBoxComponent } from './components/information-box/information-box.component';
import { VariabilityDisplaymentComponent } from './components/variability-displayment/variability-displayment.component';
import { DurationDisplaymentComponent } from './components/duration-displayment/duration-displayment.component';
import { TabGroupFilterComponent } from './components/tab-group-filter/tab-group-filter.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { LinksComponent } from './components/links/links.component';
import { PeriodicityComponent } from './components/periodicity/periodicity.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { TopNavigationMenuComponent } from './components/top-navigation-menu/top-navigation-menu.component';
import { TopNavigationMenuItemDirective } from './components/top-navigation-menu-item/top-navigation-menu-item.directive';
import { TopNavigationMenuItemComponent } from './components/top-navigation-menu-item/top-navigation-menu-item.component';
import { CustomContextMenuComponent } from './components/custom-context-menu/custom-context-menu.component';
import { ContextMenuItemDirective } from './components/custom-context-menu/context-menu-item.directive';
import { FormatWithDashesDirective } from './directives/format-with-dashes.directive';
import { SafeUrlPile } from './pipes/safe-url-pipe';

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		TranslateModule,
		RouterModule,
		MatInputModule,
		MatMenuModule,
		FormsModule,
		ReactiveFormsModule,
		FileUploadUiModule,
		DragDropUiModule,
		TooltipModule,
		NgSelectModule,
		UtilFormattingModule,
		InfiniteScrollModule,
		GtmModule,
		TabsModule,
		ChartsModule,
		CollapsibleModule,
	],
	declarations: [
		SidebarComponent,
		SidebarSecondaryComponent,
		NotificationsComponent,
		NotificationComponent,
		InputSearchComponent,
		ModalComponent,
		HeaderComponent,
		OutsideClickDirective,
		ChipComponent,
		ContextMenuComponent,
		LoadingIndicatorComponent,
		ConfirmationModalComponent,
		SelectComponent,
		AlertComponent,
		ButtonComponent,
		ButtonSmartComponent,
		LanguageSelectorComponent,
		CardComponent,
		ScanbotComponent,
		ScanDocumentComponent,
		CarouselComponent,
		AddressAutocompleteComponent,
		TooltipIndicatorComponent,
		TooltipPaneComponent,
		ButtonSwitch,
		ToggleSwitch,
		LearnMoreTextComponent,
		CardDrawingComponent,
		PoweredByComponent,
		InputSearchComponent,
		ListComponent,
		ListItemComponent,
		ButtonArrowComponent,
		PasswordValidationComponent,
		CountdownComponent,
		InputPasswordComponent,
		SafeHtmlPile,
		SafeUrlPile,
		OperRouterLinkDirective,
		HyperlinkComponent,
		OnlyNumbersDirective,
		HyperlinkRedirectComponent,
		BreadcrumbComponent,
		ProgressOverlayComponent,
		NDigitDecimaNumberDirective,
		ConsentDisclaimerComponent,
		PanelComponent,
		DeploymentAnnouncementComponent,
		ScrollToDirective,
		RadioGroupComponent,
		DurationSelectorButtonsComponent,
		CollapsiblePanelComponent,
		NumberToDecimalDirective,
		CustomTranslationComponent,
		ContenteditableValueAccessorDirective,
		PhoneInputComponent,
		ShowTooltipIfTruncatedDirective,
		PhoneNumberComponent,
		AddressPreviewPipe,
		FontawesomeIconComponent,
		StepProgressBarComponent,
		AddressPreviewComponent,
		CheckboxComponent,
		AmountDisplaymentComponent,
		ProductDisplaymentComponent,
		LabelComponent,
		ErrorIndicatorComponent,
		StatusActionButtonComponent,
		CollapseToggleComponent,
		VariabilitySelectComponent,
		PercentageDisplaymentComponent,
		CurrencyDisplaymentComponent,
		InputCurrencyComponent,
		InputPercentageComponent,
		ClickableComponent,
		CheckboxComponent,
		AmountProgressBarComponent,
		BadgeComponent,
		OnlyLettersDirective,
		InformationBoxComponent,
		VariabilityDisplaymentComponent,
		DurationDisplaymentComponent,
		TabGroupFilterComponent,
		LinksComponent,
		PeriodicityComponent,
		DropdownComponent,
		SkeletonComponent,
		TopNavigationMenuComponent,
		TopNavigationMenuItemDirective,
		TopNavigationMenuItemComponent,
		CustomContextMenuComponent,
		ContextMenuItemDirective,
		FormatWithDashesDirective
	],
	exports: [
		SidebarComponent,
		FontAwesomeModule,
		SidebarSecondaryComponent,
		NotificationsComponent,
		InputSearchComponent,
		ModalComponent,
		HeaderComponent,
		OutsideClickDirective,
		ChipComponent,
		ContextMenuComponent,
		LoadingIndicatorComponent,
		DragDropUiModule,
		FileUploadUiModule,
		ConfirmationModalComponent,
		SelectComponent,
		AlertComponent,
		ButtonComponent,
		ButtonSmartComponent,
		LanguageSelectorComponent,
		CardComponent,
		ScanbotComponent,
		ScanDocumentComponent,
		AddressAutocompleteComponent,
		TooltipIndicatorComponent,
		TooltipPaneComponent,
		ButtonSwitch,
		ToggleSwitch,
		LearnMoreTextComponent,
		CardDrawingComponent,
		PoweredByComponent,
		InputSearchComponent,
		ListComponent,
		ListItemComponent,
		ButtonArrowComponent,
		PasswordValidationComponent,
		CountdownComponent,
		InputPasswordComponent,
		SafeHtmlPile,
		SafeUrlPile,
		OperRouterLinkDirective,
		InfiniteScrollModule,
		HyperlinkComponent,
		OnlyNumbersDirective,
		HyperlinkRedirectComponent,
		BreadcrumbComponent,
		ProgressOverlayComponent,
		NDigitDecimaNumberDirective,
		ConsentDisclaimerComponent,
		PanelComponent,
		TabsModule,
		DeploymentAnnouncementComponent,
		ScrollToDirective,
		RadioGroupComponent,
		ChartsModule,
		DurationSelectorButtonsComponent,
		CollapsiblePanelComponent,
		NumberToDecimalDirective,
		CustomTranslationComponent,
		ContenteditableValueAccessorDirective,
		CollapsibleModule,
		PhoneInputComponent,
		ShowTooltipIfTruncatedDirective,
		PhoneNumberComponent,
		AddressPreviewPipe,
		AddressPreviewComponent,
		FontawesomeIconComponent,
		StepProgressBarComponent,
		CheckboxComponent,
		AmountDisplaymentComponent,
		ProductDisplaymentComponent,
		LabelComponent,
		ErrorIndicatorComponent,
		StatusActionButtonComponent,
		CollapseToggleComponent,
		VariabilitySelectComponent,
		PercentageDisplaymentComponent,
		CurrencyDisplaymentComponent,
		InputCurrencyComponent,
		InputPercentageComponent,
		ClickableComponent,
		AmountProgressBarComponent,
		BadgeComponent,
		OnlyLettersDirective,
		InformationBoxComponent,
		VariabilityDisplaymentComponent,
		DurationDisplaymentComponent,
		TabGroupFilterComponent,
		LinksComponent,
		PeriodicityComponent,
		DropdownComponent,
		SkeletonComponent,
		TopNavigationMenuComponent,
		TopNavigationMenuItemDirective,
		TopNavigationMenuItemComponent,
		CustomContextMenuComponent,
		ContextMenuItemDirective,
		FormatWithDashesDirective
	],
	providers: [AddressPreviewPipe],
})
export class UiModule {}
