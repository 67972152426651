import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { AUTH_SERVICE, IAuthService } from '../services/auth.service';

declare const COMMIT_HASH: string;

// Define list of backend routes where token is not expected
const tokenExclusionList = [
	'/configuration/version/',
	'/resources/features/',
	'/resources/language/',
	'/resources/phone-country-code/',
	'/contact/',
	'/version.json',
];

@Injectable({
	providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
	private static shouldSendToken(url: string): boolean {
		return !tokenExclusionList.reduce((acc, tokenExclusionListItem) => url.includes(tokenExclusionListItem) || acc, false);
	}

	constructor(
		readonly jwtHelperService: JwtHelperService,
		@Inject(AUTH_SERVICE) public readonly authService: IAuthService,
		@Inject(CUSTOMER_INSIGHTS_CONFIG) private readonly customerConfig: CustomerInsights
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// In the first request we still don't have a config
		if (request.url === `/assets/${COMMIT_HASH}/config/customer.json` || !request.url.startsWith(this.customerConfig.serverUrl)) {
			return next.handle(request);
		}

		const token = this.jwtHelperService.tokenGetter();

		if (token && TokenInterceptor.shouldSendToken(request.url)) {
			request = request.clone({
				setHeaders: {
					Authorization: `Token ${token}`,
				},
			});
		}

		return next.handle(request);
	}
}
