import { Injectable, Inject } from '@angular/core';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { Address, Realty, Resource } from '@oper-client/shared/data-model';

interface GoogleGeocodeAddressComponent {
	long_name: string;
	short_name: string;
	types: string[];
}

const autoCompleteAddressRealtyFields: (keyof Pick<Address, 'street' | 'houseNumber' | 'zipCode' | 'city' | 'country'>)[] = [
	'street',
	'houseNumber',
	'zipCode',
	'city',
	'country',
];

@Injectable({
	providedIn: 'root',
})
export class GoogleGeocodeService {
	constructor(@Inject(CUSTOMER_INSIGHTS_CONFIG) public customerConfig: CustomerInsights) {}

	public googleAddressComponentsToRealty(
		addressComponents: GoogleGeocodeAddressComponent[],
		countryResources: Resource[],
		realtyData?: Partial<Realty>
	): Partial<Realty> {
		return addressComponents.reduce(
			(acc, item) => {
				if (item.types.includes('street_number')) {
					return { ...acc, address: { ...acc.address, houseNumber: item.long_name } };
				} else if (item.types.includes('route')) {
					return { ...acc, address: { ...acc.address, street: item.long_name } };
				} else if (item.types.includes('postal_code')) {
					return { ...acc, address: { ...acc.address, zipCode: item.long_name } };
				} else if (item.types.includes('locality')) {
					return {
						...acc,
						address: { ...acc.address, city: item.long_name },
					};
				} else if (item.types.includes('country')) {
					const countryResource = countryResources.find((country) => country.definition === item.short_name);
					return {
						...acc,
						address: {
							...acc.address,
							country: { id: countryResource.id },
						},
					};
				}
				return acc;
			},
			{ ...realtyData }
		);
	}

	public googleAddressComponentsToAddressForm(
		addressComponents: GoogleGeocodeAddressComponent[],
		countryResources: Resource[],
		realtyData?: Partial<Realty>,
		prefix = ''
	): any {
		const addressFormValue = {};
		const realty: Partial<Realty> = this.googleAddressComponentsToRealty(addressComponents, countryResources, realtyData);
		if (realty?.address) {
			autoCompleteAddressRealtyFields.forEach((field) => {
				if (typeof realty?.address[field] !== 'undefined') {
					if (field === 'country') {
						addressFormValue[`${prefix}${field}.id`] = realty.address.country?.id || '';
					} else {
						addressFormValue[`${prefix}${field}`] = realty.address[field];
					}
				}
			});
		}
		return addressFormValue;
	}
}
